/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col
} from "reactstrap";

class AdultCoursesTabs extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    return (
      <>
        <Row className="justify-content-right">
          <Col className="mt-5 mt-lg-0" lg="6">
            {/* Menu */}
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    Beginner
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    Intermediate
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 3
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 3)}
                    href="#pablo"
                    role="tab"
                  >
                    Advanced
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                  <TabPane tabId="plainTabs1">
                    <p className="description text-justify">
                      This course is intended for people who have no prior knowledge of French
                      and who would like to learn the basics.
                    </p>
                    <p className="description text-justify">
                      At the end of this course, learners are expected to be fully prepared to
                      read, write, understand, and speak at a basic level.
                    </p>
                  </TabPane>
                  <TabPane tabId="plainTabs2">
                    <p className="description text-justify">
                      This course is intended for people who have acquired a basic knowledge of French
                      and who want to improve in order to have a better understanding of the language.
                    </p>
                    <p className="description text-justify">
                      At the end of this course, learners will be able to express themselves
                      with a richer vocabulary and communicate more confidently.
                    </p>
                  </TabPane>
                  <TabPane tabId="plainTabs3">
                    <p className="description text-justify">
                      The course is intended for people who have acquired a reasonable level in French
                      language but who want to extend their knowledge of the language in order to
                      feel comfortable during conversations, during presentations in French
                      and reach perfection while writing French texts or while translating English texts into French.
                    </p>
                    <p className="description text-justify">
                      At the end of this course, learners will be properly equipped to
                      communicate easily in business talks involving complex terminologies.
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default AdultCoursesTabs;
