/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";

class ContactUs extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-square-pin" />
                    </div>
                    <p>Our office is located at:</p>
                    <p>
                      83, Kennedy Rd South <br />
                      Unit 1805 <br />
                      Brampton <br />
                      Ontario L6W 3P3 <br />
                    </p>

                    <p>
                    Phone: 905-783-2709 <br />
                    Email: <a href="mailto:info@melcademy.ca">info@melacademy.ca</a> <br />
                    </p>
                  </div>
                </Col>
                <Col className="order-md-2 " md="6">
                  <div className="">
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5770.102684272963!2d-79.740939!3d43.688696!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDPCsDQxJzE5LjMiTiA3OcKwNDQnMjcuNCJX!5e0!3m2!1sen!2sus!4v1585434304240!5m2!1sen!2sus" 
                    width="600" 
                    height="450" 
                    frameborder="0"                   
                    allowfullscreen="" 
                    aria-hidden="false" 
                    tabindex="0"
                    title="map"
                    className="embed-responsive iframe">
                  </iframe>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </section>          
        </main>
      </>
    );
  }
}

export default ContactUs;
