/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Container
} from "reactstrap";

class AboutUsTabs extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    return (
      <>
        <Row className="justify-content-center">
          <Col className="mt-5 mt-lg-0" lg="6">
            {/* Menu */}
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    English
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    French
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                  <TabPane tabId="plainTabs1">
                    <section className="section">
                      <Container>
                        <Row className="align-items-center">
                          <h2 className="title text-success">
                            About Us
                  </h2>

                        </Row>
                      </Container>
                      <Container>
                        <Row className="row-grid align-items-center">
                          <Col className="order-md-1" md="10">
                            <div className="pr-md-5">
                              <p>
                                Mel Academy is an institution with a long and rich tradition in the field of education. For more than twenty years, we have provided academic training to young people as well as adults. The results have often gone beyond our expectations: numerous ex-students of ours have excelled in different fields like medicine, judiciary, finance, insurance or teaching.
                    </p>
                              <p>
                                Our team of educators are professionals in all the subjects that we offer and we are confident they will continue to contribute towards the success of those who put their trust in us.
                    </p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                    <section className="">

                      <Container>
                        <Row className="row-grid align-items-center">
                          <h2 className="title text-success">
                            Our People
                  </h2>
                          <div className="separator"></div>
                        </Row>
                      </Container>
                      <Container>
                        <Row className="row-grid">
                          <Col className="flex-column order-md-2" md="6">
                            <div className="pr-md-5">
                              <h4 className="title text-success">
                                Mel
                              </h4>
                              <p>
                                Mel holds a Masters degree from the most famous French University: Paris 4 – Sorbonne. Having twice been awarded a scholarship by the French government, he has subsequently had a long and fruitful career as a teacher of French and French literature. He has also presented educational programmes on television in these subjects.
                                </p>
                            </div>
                          </Col>
                          <Col className="flex-column order-md-3" md="6">
                            <div className="pr-md-5">
                              <h4 className="title text-success">
                                Veni
                  </h4>
                              <p>
                                Veni is a qualified teacher who is specialized in French as a second language. She holds a University degree in Mathematics and Statistics (BSc), a PGCE, and a diploma in Education and Social Studies. She is also a gold medalist in Computer studies.
                  </p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                  </TabPane>
                  <TabPane tabId="plainTabs2">
                    <section className="section">
                      <Container>
                        <Row className="align-items-center">
                          <h2 className="title text-success">
                            A Propos
                  </h2>

                        </Row>
                      </Container>
                      <Container>
                        <Row className="row-grid align-items-center">
                          <Col className="order-md-1" md="10">
                            <div className="pr-md-5">
                              <p>
                                Mel Academy est une institution solidement ancrée dans le domaine de l’éducation. Pendant plus de vingt ans, nous avons contribué à la formation académique des jeunes et des moins jeunes. Les résultats ont dépassé nos espérances : nombreux sont nos ex-élèves qui ont brillé dans différents domaines tels que la médecine, le judiciaire, la finance, l’assurance ou l’enseignement.
                    </p>
                              <p>
                                Nos éducateurs sont des professionnels dans toutes les matières que nous offrons et nous sommes convaincus qu’ils  vont continuer de contribuer au succès de ceux qui nous feront confiance.
                    </p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                    <section className="">
                      <Container>
                        <Row className="row-grid align-items-center">
                          <h2 className="title text-success">
                            Nos Enseignants
                                              </h2>
                          <div className="separator"></div>
                        </Row>
                      </Container>

                      <Container>
                        <Row className="row-grid">
                          <Col className="order-md-2" md="6">
                            <div className="pr-md-5">
                              <h4 className="title text-success">
                                Mel
                  </h4>
                              <p>
                                Mel détient une Maitrise en Lettres Modernes de la très célèbre université française : Paris 4 – Sorbonne. Ayant à deux reprises bénéficié d’une bourse française, il a connu une longue et riche carrière en tant qu’enseignant de français et de littérature française. Il a aussi animé des émissions éducatives à la télévision dans ces matières.
                    </p>
                            </div>
                          </Col>
                          <Col className="order-md-3" md="6">
                            <div className="pr-md-5">
                              <h4 className="title text-success">
                                Veni
                  </h4>
                              <p>
                                Veni est une enseignante qualifiée qui s’est aussi spécialisée en français comme langue seconde. En outre, elle est titulaire d’un diplôme universitaire en Mathématiques et en Statistiques (BSc) aussi bien que d’un PGCE et d’un diplôme en éducation et en sciences sociales. Elle est aussi détentrice d’une médaille d’or en informatique.
                  </p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default AboutUsTabs;
