/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col
} from "reactstrap";

import AdultCoursesTabs from 'views/sections/AdultCoursesTabs.js';
import StudentCoursesTabs from 'views/sections/StudentCoursesTabs.js';

class CoursesOfferedTabs extends React.Component {
  state = {
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    return (
      <>
        <Row className="justify-content-left">
          <Col className="mt-5 mt-lg-0" lg="6">
            {/* Menu */}
            <div className="nav-wrapper">
              <Nav
                className="nav-fill "
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    For Adults
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    For Students
                  </NavLink>
                </NavItem>
              </Nav>

            </div>

          </Col>
        </Row>
        <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
          <TabPane tabId="plainTabs1">
            <div className="row-grid align">
              <AdultCoursesTabs />
            </div>
          </TabPane>
          <TabPane tabId="plainTabs2">
            <div className="row-grid align">
              <StudentCoursesTabs />
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}

export default CoursesOfferedTabs;
